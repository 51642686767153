<script>
import Layout from '../../layouts/main';
import Product from '@/components/widgets/loading-product';
import { required } from 'vuelidate/lib/validators';
import { VMoney } from 'v-money';
import { api } from '@/state/services';
import firebase from 'firebase/app';
import "firebase/database";

export default {
  locales: {
    pt: {
      'Auction': 'Leilão',
      'Bid': 'Dar lance',
      'Last bids': 'Últimos Lances',
      'New Bid': 'Novo lance',
      'Value': 'Valor',
      'Send': 'Enviar',
      'Value is required.': 'Valor é obrigatório',

      'The auction entered does not exist or is no longer available.': 'O leilão não existe ou não está mais disponível.',
      'You don\'t have enough balance to bid.': 'Você não tem saldo suficiente para dar um lance.',
      'The value entered is invalid.': 'O valor inserido é inválido.',
      'You have already bid with the same amount.': 'Você já deu um lance com o mesmo valor.',
      'We were unable to bid, please try again.': 'Não foi possível dar o lance, tente novamente.',
      'Your bid was successfully processed.': 'Seu lance foi processado com sucesso.',
    },
    es: {
      'Auction': 'Leilão',
      'Bid': 'Dar lance',
      'Last bids': 'Últimos Lances',
      'New Bid': 'Novo lance',
      'Value': 'Valor',
      'Send': 'Enviar',
      'Value is required.': 'Se requiere valor.',

      'The auction entered does not exist or is no longer available.': 'O leilão não existe ou não está mais disponível.',
      'You don\'t have enough balance to bid.': 'Você não tem saldo suficiente para dar um lance.',
      'The value entered is invalid.': 'O valor inserido é inválido.',
      'You have already bid with the same amount.': 'Você já deu um lance com o mesmo valor.',
      'We were unable to bid, please try again.': 'Não foi possível dar o lance, tente novamente.',
      'Your bid was successfully processed.': 'Seu lance foi processado com sucesso.',
    }
  },
  components: { Layout, Product },
  data() {
    return {
      product: {
        id: this.$route.params.id,
        date: {
          start: null,
          end: null,
        },
        name: null,
        description: null,
        price: null,
        cost: null,
        image: null,
        value: null,

        loading: true,
        errored: false,
        empty: false,
      },

      bid: {
        modal: false,
        loading: false,
        error: '',
        alert: {
          type: '',
          message: ''
        },
        id: '',
        value: '',
      },

      bids: {
        loading: true,
        total: 0,
        list: [],
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
   };
  },
  validations: {
    bid: {
      value: { required },
    }
  },
  directives: { money: VMoney },
  methods: {
    getProduct() {
      api
      .get('auction/' + this.product.id)
      .then((response) => {
        if (response.data.status == 'success') {
          this.product.id = response.data.id
          this.product.date.start = response.data.date.start
          this.product.date.end = response.data.date.end
          this.product.name = response.data.name
          this.product.description = response.data.description
          this.product.price = response.data.price
          this.product.cost = response.data.cost
          this.product.image = response.data.image
          this.product.loading = false
        }
      })
      .catch(error => {
        this.product.errored = error
        this.product.loading = false

        this.$router.push('/auction/list');
      })
      .finally(() => {
        this.product.loading = false
      })
    },
    bitSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { value } = this.bid;
        if (value) {
          this.bid.loading = true
          api
            .post('auction/bid', {
              id: this.product.id,
              value: this.bid.value,
            })
            .then(response => {
              if (response.data.status=='success') {
                this.bid.id = ''
                this.bid.value = ''
                this.$v.$reset()

                this.bid.loading = false
                this.bid.modal = false

                this.$noty.success(this.t(response.data.message))
              } else {
                this.bid.alert.type = 'alert-danger'
                this.bid.alert.message = response.data.message
                this.bid.loading = false
              }

              this.bid.loading = false
            })
          .catch(error => {
            if (error) {
              this.bid.loading = false
              this.bid.modal = false
              this.$noty.error('Não foi possível processar seu lance, tente novamente.')
            }
          })
          .finally(() => {
          })
        }
      }
    }
  },
  mounted() {
    this.getProduct()

    var ref = firebase.database().ref('auction/' + this.product.id)

    ref.on('value', (snapshot) => {
      this.bids.total = snapshot.numChildren()
    })

    ref.limitToLast(10).on('value', (snapshot) => {
      this.bids.loading = true
      this.bids.list = [];

      snapshot.forEach((doc) => {
        this.bids.list.unshift(doc.val());
      });

      this.bids.loading = false
    })
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Auction') }}</h4>
        </div>
      </div>
    </div>
    <div v-if="product.loading" class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body text-center">
            <div class="mb-3">
              <img :src="product.image" class="w-100" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">{{ product.name }}</h5>
            <h4 class="mb-0">{{ product.price }}</h4>
            <p class="font-size-12 text-uppercase">Preço de Mercado</p>
            <p class="text-uppercase font-size-13 m-0">Custo por lance de {{ product.cost }}</p>
            <p class="text-uppercase font-size-13">Lances permitidos até {{ product.date.end }}</p>
            <hr>
            <p v-html="product.description"></p>
            <button class="btn btn-danger btn-block text-uppercase" @click="bid.modal = true">{{ t('Bid') }}</button>
          </div>
        </div>
        <div class="card d-none">
          <div class="card-body">
            <h3 class="mb-0">{{ bids.total }}</h3>
            <p class="mb-0 font-size-12 text-uppercase">Total de Lance</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="!product.loading">
        <div class="card">
          <div class="card-body">
            <h5>{{ t('Last bids') }}</h5>
            <hr>
            <div v-if="bids.loading" class="text-center"><b-spinner small variant="danger" role="status"></b-spinner></div>
            <div v-else-if="bids.list.length == 0">Não há lances registrados.</div>
            <div v-else class="d-flex align-items-center border-bottom pb-2 mb-2" v-for="(bid, index) in bids.list" :key="index">
              <div class="mr-2">
                <img v-if="bid.avatar" style="width:35px;" class="card-img avatar rounded-circle" :src="bid.avatar" alt="">
                <img v-else style="width:35px;" class="card-img avatar rounded-circle" src="@/assets/images/logo-icon.png" alt="">
              </div>
              <div> {{ bid.username }} </div>
              <div class="flex-fill text-right font-size-11">
                {{ bid.date }}<br>
                #{{ bid.code }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="bid.modal" :title="t('New Bid')" centered>
      <b-form @submit.prevent="bitSubmit">
        <div v-if="bid.alert.message" :class="'alert ' + bid.alert.type">{{ t(bid.alert.message) }}</div>
        <b-form-group id="bid-value" :label="t('Digite o valor do seu menor lance único')" label-for="bid-value">
          <b-form-input id="bid-value" v-model="bid.value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.bid.value.$error }" autocomplete="off" autofocus></b-form-input>
          <div v-if="$v.bid.value.$error" class="invalid-feedback">
            <span v-if="!$v.bid.value.required">{{ t('Value is required.') }}</span>
          </div>
        </b-form-group>
        <b-button :disabled="bid.loading == true || bid.value == 'R$ 0,00'" type="submit" variant="danger">
          {{ t('Send') }}
          <b-spinner v-if="bid.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>